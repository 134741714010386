import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import lipInfusionsRestoreProduct from "../images/product-and-package/lip-infusions-restore-bleed.svg"
import lipInfusionsRestoreImage from "../images/products/lip-infusions-restore-large.png"

// related product images
import lipInfusionsNourishProduct from "../images/product-and-package/lip-infusions-nourish.jpg"
import lipInfusionsSoothingProduct from "../images/product-and-package/lip-infusions-soothing.jpg"
import amazonLogo from "../images/stockists/amazon.png"
import bootsLogo from "../images/stockists/boots.png"
import tescoLogo from "../images/stockists/tesco.png"
//import wilkoLogo from "../images/stockists/wilko.png"

import everydayIcon from "../images/icons/product-icon/everyday.svg"
import outdoorsIcon from "../images/icons/product-icon/outdoors.svg"
import coldIcon from "../images/icons/product-icon/winter.svg"
import nightIcon from "../images/icons/product-icon/overnight.svg"

const product = {
  image: lipInfusionsRestoreProduct,
  productImage: lipInfusionsRestoreImage,
  productImageStyle: "bleed",
  title: (
    <h1 className="product-overview__heading">Lip Infusions - Restore.</h1>
  ),
  alt: "Lip Infusions - Restore.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Infused with Squalane
      <br />
    </h2>
  ),
  disclaimer: "",
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "outdoors", icon: outdoorsIcon },
    { title: "cold weather", icon: coldIcon },
    { title: "at night", icon: nightIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>
        Blistex Lip Infusions Restore has an advanced lip care formula infused
        with a blend of ingredients that restores lips' moisture and leaves them
        feeling naturally soft and smooth again.
      </p>
      <ul>
        <li>
          Infused with Squalane, a hydrator known to help replenish the skin's
          moisture barrier
        </li>
        <li>
          Fortified with Allantoin, known to provide gentle and nonabrasive
          exfoliation that softens and smooths lips
        </li>
        <li>Dermatologist approved</li>
      </ul>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Boots",
      stockistLink:
        "https://www.boots.com/blistex-lip-infusions-restore-lip-balm-10317368",
      stockistImage: bootsLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Amazon",
      stockistLink:
        "https://www.amazon.co.uk/Blistex-Infusions-Restore-Chapped-Coconut/dp/B09X61KCKS/ref=sr_1_6?crid=2NQHEXMB0T051&keywords=blistex+lip+infusions&qid=1654701142&sprefix=blistex+lip+in%2Caps%2C74&sr=8-6",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Tesco",
      stockistLink:
        "https://www.tesco.com/groceries/en-GB/products/314600795",
      stockistImage: tescoLogo,
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        Simply twist the stick and apply evenly to your lips. Re-apply as often
        as you like, especially if you're out and about in dry, cold or sunny
        weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      hydrogenated coconut oil, octyldodecanol, euphorbia cerifera (candelilla)
      wax, cera alba, phenyl trimethicone, caprylic/capric triglyceride,
      bis-diglyceryl polyacyladipate-2, dimethicone, myristyl myristate, C10-30
      cholesterol/lanosterol esters, aroma, jojoba esters, squalane, tocopheryl
      acetate, allantoin, alumina, arachidyl alcohol, arachidyl glucoside,
      behenyl alcohol, copernicia cerifera (carnauba) wax, geraniol, hibiscus
      rosa-sinensis flower extract, limonene, linalool, phenoxyethanol,
      polyhydroxystearic acid, saccharin, stearic acid, CI 77891
    </p>
  ),
  faqs: [
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, beeswax.",
    },
    {
      question: "Do you sell this product online?",
      answer:
        "Lip Infusions Restore is available to buy online through key retailers. Please click on the where to buy link on each product page.",
    },
  ],
  relatedProducts: [
    {
      productName: "Lip Infusions - Nourish.",
      productLink: "/lip-infusions-nourish",
      productImage: lipInfusionsNourishProduct,
    },
    {
      productName: "Lip Infusions - Soothing.",
      productLink: "/lip-infusions-soothing",
      productImage: lipInfusionsSoothingProduct,
    },
  ],
}

const lipInfusionsRestore = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Lip Infusions - Restore." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default lipInfusionsRestore
